@import url(https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,800;1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,800;1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,800;1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,800;1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,800;1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,800;1,100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: black;
}

.App {
  position: relative;  
}

fieldset {
  border: none;
}

.addLocationForm {
  border: none;
  height: auto;
}
.addLocationForm input, textarea {
  width: 100%;
  background-color: #2e2e2e;
  font-size: 20px;
  border: none;
  border-bottom: 1px solid gray;
  font-family: bitter;
}
.addLocationBtn {
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 35px;
  border: none;
  top: 10px;
  background-color: green;
  cursor: pointer;
}
.listViewBtn {
  transition: .5s;
  display: none;
}
.error {
  color: #f05454;
}

.clearSearchBtn {
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 35px;
  border: none;
  top: 20px;
  background-color: #f05454;
  cursor: pointer;
  margin-bottom: 15px;
}

@media only screen and (max-width: 950px) {
  body {
    overflow: hidden;
  }
  .listViewBtn {
    border-radius: 5px;
    position: fixed;
    display: block;
    width: 75%;
    left: 12.5%;
    height: 35px;
    border: none;
    bottom: 10px;
    background-color: #2e2e2e;
    cursor: pointer;
  }
}
* {
    color: #c7c7c5;
}

.card {
    margin-top: 10px;
    width: 100%;
    height: auto;
    border-radius: 2%;
    background-color: #595958;
}

.card:hover {
    cursor: pointer;
}

.card h4, p {
    font-family: bitter;
}

.card h4 {
    padding: 10px 10px 10px;
    font-weight: 800;
}
.card p {
    padding: 10px 10px 10px;
    font-weight: 400;
    margin-top: -25px;
}
.listView-wrapper {
    position: absolute;
    margin-top: 20px;
    margin-left: 20px;
    height: 85vh;
    width: 275px;
    top:0;
    padding: 20px;
    border-radius: 2%;
    box-shadow: 0 0 5px rgba(255,255,255,.1);
    background: #2d2e2e;
    transition: .5s;
  }
  
  .listView {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .listView h1 {
    color: #c7c7c5;
    text-align: center;
    font-family: 'Bitter';
    font-weight: 800;
  }
  .listView-scroll {
    overflow-x: hidden;
    position: relative;
    height: 63vh;
    overflow: scroll;
  }
  .listViewAdd-btn {
    border-radius: 5px;
    position: relative;
    width: 50%;
    height: 35px;
    border: none;
    top: 10px;
    background-color: green;
    cursor: pointer;
  }
  .listViewSearch-btn {
    border-radius: 5px;
    position: relative;
    width: 45%;
    height: 35px;
    border: none;
    top: 10px;
    cursor: pointer;
    background-color: #658deb;
    left: 5%;
  }
  @media only screen and (max-width: 950px) {
    .listView-wrapper {
      display: block;
      height: 75%;
      width: 65%;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-55%, -65%);
              transform: translate(-55%, -65%);
    }
    .listView-scroll {
      height: 52vh;
    }
  }
.auth-wrapper {
    position: absolute;
    height: auto;
    width: 275px;
    padding: 20px;
    border-radius: 2%;
    box-shadow: 0 0 5px rgba(255,255,255,.1);
    background: #2d2e2e;
    transition: .5s;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, 80%);
            transform: translate(-50%, 80%);
}
.auth {
    height: 100%;
    text-align: center;
}
.auth h1 {
    margin-top: -5px;
    font-family: Bitter;
    font-weight: 800;
}
.auth input, textarea {
    width: 100%;
    background-color: #2e2e2e;
    font-size: 20px;
    border: none;
    border-bottom: 1px solid gray;
    font-family: bitter;
  }
  .auth button {
    border-radius: 5px;
    position: relative;
    width: 100%;
    height: 35px;
    border: none;
    top: 10px;
    background-color: green;
    cursor: pointer;
    margin-bottom: 10px;
  }
.customOverlay {
    background: rgba(0,0,0,.8);
}
.customModal {
    font-family: bitter;
    background: #2e2e2e;
    max-width: 500px;
}
.customModal h1 {
    font-weight: 800;
}
.customModal p {
    font-weight: 400;
}


.customOverlay {
    background: rgba(0,0,0,.8);
}
.customModal {
    font-family: bitter;
    background: #2e2e2e;
    max-width: 500px;
}
.customModal h1 {
    font-weight: 800;
}
.customModal p {
    font-weight: 400;
}



.customOverlay {
    background: rgba(0,0,0,.8);
}
.customModal {
    font-family: bitter;
    background: #2e2e2e;
    max-width: 500px;
}
.customModal h1 {
    font-weight: 800;
}
.customModal p {
    font-weight: 400;
}


