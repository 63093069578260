@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,800;1,100&display=swap');

body {
  background: black;
}

.App {
  position: relative;  
}

fieldset {
  border: none;
}

.addLocationForm {
  border: none;
  height: auto;
}
.addLocationForm input, textarea {
  width: 100%;
  background-color: #2e2e2e;
  font-size: 20px;
  border: none;
  border-bottom: 1px solid gray;
  font-family: bitter;
}
.addLocationBtn {
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 35px;
  border: none;
  top: 10px;
  background-color: green;
  cursor: pointer;
}
.listViewBtn {
  transition: .5s;
  display: none;
}
.error {
  color: #f05454;
}

.clearSearchBtn {
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 35px;
  border: none;
  top: 20px;
  background-color: #f05454;
  cursor: pointer;
  margin-bottom: 15px;
}

@media only screen and (max-width: 950px) {
  body {
    overflow: hidden;
  }
  .listViewBtn {
    border-radius: 5px;
    position: fixed;
    display: block;
    width: 75%;
    left: 12.5%;
    height: 35px;
    border: none;
    bottom: 10px;
    background-color: #2e2e2e;
    cursor: pointer;
  }
}