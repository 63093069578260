@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,800;1,100&display=swap');

.customOverlay {
    background: rgba(0,0,0,.8);
}
.customModal {
    font-family: bitter;
    background: #2e2e2e;
    max-width: 500px;
}
.customModal h1 {
    font-weight: 800;
}
.customModal p {
    font-weight: 400;
}

