.listView-wrapper {
    position: absolute;
    margin-top: 20px;
    margin-left: 20px;
    height: 85vh;
    width: 275px;
    top:0;
    padding: 20px;
    border-radius: 2%;
    box-shadow: 0 0 5px rgba(255,255,255,.1);
    background: #2d2e2e;
    transition: .5s;
  }
  
  .listView {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .listView h1 {
    color: #c7c7c5;
    text-align: center;
    font-family: 'Bitter';
    font-weight: 800;
  }
  .listView-scroll {
    overflow-x: hidden;
    position: relative;
    height: 63vh;
    overflow: scroll;
  }
  .listViewAdd-btn {
    border-radius: 5px;
    position: relative;
    width: 50%;
    height: 35px;
    border: none;
    top: 10px;
    background-color: green;
    cursor: pointer;
  }
  .listViewSearch-btn {
    border-radius: 5px;
    position: relative;
    width: 45%;
    height: 35px;
    border: none;
    top: 10px;
    cursor: pointer;
    background-color: #658deb;
    left: 5%;
  }
  @media only screen and (max-width: 950px) {
    .listView-wrapper {
      display: block;
      height: 75%;
      width: 65%;
      top: 50%;
      left: 50%;
      transform: translate(-55%, -65%);
    }
    .listView-scroll {
      height: 52vh;
    }
  }