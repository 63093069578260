@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,800;1,100&display=swap');

* {
    color: #c7c7c5;
}

.card {
    margin-top: 10px;
    width: 100%;
    height: auto;
    border-radius: 2%;
    background-color: #595958;
}

.card:hover {
    cursor: pointer;
}

.card h4, p {
    font-family: bitter;
}

.card h4 {
    padding: 10px 10px 10px;
    font-weight: 800;
}
.card p {
    padding: 10px 10px 10px;
    font-weight: 400;
    margin-top: -25px;
}