@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,800;1,100&display=swap');

.auth-wrapper {
    position: absolute;
    height: auto;
    width: 275px;
    padding: 20px;
    border-radius: 2%;
    box-shadow: 0 0 5px rgba(255,255,255,.1);
    background: #2d2e2e;
    transition: .5s;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 80%);
}
.auth {
    height: 100%;
    text-align: center;
}
.auth h1 {
    margin-top: -5px;
    font-family: Bitter;
    font-weight: 800;
}
.auth input, textarea {
    width: 100%;
    background-color: #2e2e2e;
    font-size: 20px;
    border: none;
    border-bottom: 1px solid gray;
    font-family: bitter;
  }
  .auth button {
    border-radius: 5px;
    position: relative;
    width: 100%;
    height: 35px;
    border: none;
    top: 10px;
    background-color: green;
    cursor: pointer;
    margin-bottom: 10px;
  }